import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { smallFontSizeAnimation } from '@core/constants/animations';
import { UserModel } from '@app/shared/models/user.model';
import { filter, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [smallFontSizeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {

  @Input()
  public isOpen: boolean = true;

  @Output()
  public toggleSidebar = new EventEmitter<void>();

  public activeItem: string = '';

  constructor(
    private readonly _authService: AuthService,
    private readonly router: Router,
  ) {
    this.updateActiveItem();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntilDestroyed(),
    ).subscribe(() => this.updateActiveItem());
  }

  public get userData$(): Observable<UserModel | null> {
    return this._authService.userData$;
  }

  public isTabAvailable(tab: string): boolean {
    switch (tab) {
    case 'administration':
      return this._authService.hasGroupsForAdministration;
    case 'settings':
    case 'tg-users':
      return this._authService.isSuperAdmin;
    default:
      return false;
    }
  }

  public onLogoutEvent(): void {
    this._authService.logout();
  }

  public setActiveItem(item: string): void {
    this.activeItem = item;
  }

  private updateActiveItem(): void {
    const routesMap: { [key: string]: string }= {
      '/groups': 'Groups',
      '/administration': 'Administration',
      '/tg-users': 'Telegram IDs',
      '/settings': 'System Settings',
    };

    const activeRoute = Object.keys(routesMap).find((route) => this.router.url.includes(route));
    this.activeItem = activeRoute ? routesMap[activeRoute] : '';
  }

}
